import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarService, SharedService } from '../../services/service.index';
import { UserClass } from '../../interfaces/UserInterface';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styles: []
})

export class SidebarComponent implements OnInit {
  user: UserClass;
  // menu: any;
  menu: any = [];
  version: string = "2.0.01";

  constructor(
    public _sb: SidebarService,
    private router: Router, 
    private sharedService: SharedService
  ) { }

  ngOnInit() {

    // const menuObservable = this._sb.getMenu();
    // menuObservable.subscribe((menuData : any) => {
    //   this.menu = menuData;
    // });

    const role = this.sharedService.getRole();
    console.log(role);
    // // console.log("ROL -----", this.rol);
    // // this.user = sharedService.decodeJwt();
    // // this.rol = this.user.rol;
    if (role == 'Administrador') {
      this.menu = [
        {
          titulo: "Panel Administrativo",
          icon: "mdi mdi-home",
          submenu: [],
          url: "/dashboard",
        },
        {
          titulo: "Servicios",
          icon: "mdi mdi-car",
          url: "",
          submenu: [
            { titulo: "Nuevo servicio", url: "/services/point-to-point" },
            { titulo: "Multi punto", url: "/services/multi-point" },
            { titulo: "Servicios Activos", url:"/admin/active-services" },
            { titulo: "Servicios programados", url: "/admin/scheduled-services" },
            { titulo: "Historial", url: "/admin/service-history" }
            /* { titulo: 'Compare precios', url: '/services/compare-princing' }, */
          ],
        },
        // {
        //   titulo: "Historial",
        //   icon: "mdi mdi-history",
        //   url: "",
        //   submenu: [
        //     { titulo: "Servicios activos", url: "/history/active-services" },
        //     {
        //       titulo: "Servicios programados",
        //       url: "/history/schedule-services",
        //     },
        //     {
        //       titulo: "Historial de Servicios",
        //       url: "/history/finished-services",
        //     },
        //   ],
        // },
        {
          titulo: "Gestión de pagos",
          icon: "mdi mdi-coin",
          url: "",
          submenu: [
            //{ titulo: "Consolidados", url: "/payment-center/payment-summary" },
            {
              titulo: "Pendientes por pagar",
              //url: "/payment-center/pending-payments",
              url: "/payment-center/payment-summary",
            },
            {
              titulo: "Pendientes por recibir",
              //url: "/payment-center/payments-to-receive",
              url: "/payment-center/payment-summary",
              params: {"status": "received"},
            },
          ],
        },
        {
          titulo: "Billetera",
          icon: "mdi mdi-credit-card",
          url: "/wallet/method-payment",
          /* submenu: [
            { titulo: 'Métodos de pago', url: '/wallet/method-payment' },
            { titulo: 'Facturación', url: '/wallet/billing' }
          ] */
        },
        {
          titulo: "Usuarios",
          icon: "mdi mdi-account-outline",
          url: "/users",
          submenu: [],
        },
        /* {
          titulo: 'Referidos',
          icon: 'mdi mdi-account-star',
          url: '/referrals/referrals-types'
        },*/
        {
          titulo: "Kamgus Store",
          icon: "mdi mdi-store",
          url: "/store/products",
          /*submenu: [
            { titulo: 'Productos', url: '/store/products' },
            { titulo: 'Tienda', url: '/store/stores' },
            { titulo: 'Entregas', url: '/store/deliveries' }
          ]*/
        },
        {
          titulo: "Carga consolidada",
          icon: "mdi mdi-truck",
          url: "/massive-load/products",
          /*submenu: [
            { titulo: 'Productos', url: '/massive-load/products' },
            { titulo: 'Tiendas', url: '/massive-load/stores' },
            { titulo: 'Seguimientos', url: '/massive-load/follows' }
          ]*/
        },
        {
          titulo: "Soporte",
          icon: "mdi mdi-headphones",
          url: "/support/",
          /*submenu: [
            { titulo: 'Soporte', url: '/support/' },
            { titulo: 'Preguntas', url: '/support/faq' }
          ]*/
        },
        /*  {
          titulo: 'Preguntas frecuentes',
          icon: 'mdi mdi-comment-question-outline',
          url: '',
          submenu: []
        }, */
        {
          titulo: "Configuraciones",
          icon: "mdi mdi-settings",
          url: "/admin/settings",
          submenu: [],
        },
        {
          titulo: "Errores",
          icon: "mdi mdi-bug",
          url: "/",
          submenu: [],
        },
        {
          titulo: "Administración",
          icon: "mdi mdi-bulletin-board",
          url: "",
          submenu: [
            { titulo: "Roles", url: "/admin" },
            { titulo: "Licencias", url: "/admin/license" },
            { titulo: "Usuarios", url: "/admin/users" },
          ],
        },
      ];
    } 
    
    if (role == 'Cliente') {
      this.menu = [
        {
          titulo: "Servicios",
          icon: "mdi mdi-car",
          url: "",
          submenu: [
            { titulo: "Nuevo servicio", url: "/" },
            // { titulo: "Servicios activos", url: "/history/active-services" },
            // { titulo: 'Servicios programados', url: '/history/schedule-services' },
            // {
            //   titulo: "Historial de servicios",
            //   url: "/history/finished-services",
            // },
            { titulo: "Servicios Activos", url:"/admin/active-services" },
            { titulo: "Servicios programados", url: "/admin/scheduled-services" },
            { titulo: "Historial", url: "/admin/service-history" }
          ],
        },
        {
          titulo: "Billetera",
          icon: "mdi mdi-credit-card",
          url: "/wallet/method-payment",
          /* submenu: [
            { titulo: 'Métodos de pago', url: '/wallet/method-payment' },
            { titulo: 'Facturación', url: '/wallet/billing' }
          ] */
        },
        /*{
          titulo: 'Referidos',
          icon: 'mdi mdi-account-star',
          url: '/referrals/referrals-types'
        },*/

        {
          titulo: "Soporte",
          icon: "mdi mdi-headphones",
          url: "/support/",
          /* submenu: [
            { titulo: 'Soporte', url: '/support/' },
            { titulo: 'Preguntas', url: '/support/faq' }
          ]*/
        },
      ];
    } 
    
    if (role == 'Empresa') {
      this.menu = [
        {
          titulo: "Panel Administrativo",
          icon: "mdi mdi-home",
          submenu: [],
          url: "/dashboard",
        },
        {
          titulo: "Servicios",
          icon: "mdi mdi-car",
          url: "",
          submenu: [
            { titulo: "Multi punto", url: "/services/multi-point" },
            // {
            //   titulo: "Servicios activos",
            //   url: "/history/active-services-company",
            // },
            /*{ titulo: 'Servicios programados', url: '/history/schedule-services' },*/
            // {
            //   titulo: "Historial de Servicios",
            //   url: "/history/finished-services-company",
            // },
            { titulo: "Servicios Activos", url:"/admin/active-services" },
            { titulo: "Servicios programados", url: "/admin/scheduled-services" },
            { titulo: "Historial", url: "/admin/service-history" }
          ],
          /*submenu: [
            { titulo: 'Nuevo servicio', url: '/services/point-to-point' },
            { titulo: 'Multi punto', url: '/services/multi-point' },
            { titulo: 'Compare precios', url: '/services/compare-princing' }, 
          ]*/
        },
        /*{
          titulo: 'Historial',
          icon: 'mdi mdi-history',
          url: '',
          submenu: [
            { titulo: 'Servicios activos', url: '/history/active-services' },
            { titulo: 'Servicios programados', url: '/history/schedule-services' },
            { titulo: 'Historial de Servicios', url: '/history/finished-services' }, 
          ]
        },*/
        {
          titulo: "Gestión de pagos",
          icon: "mdi mdi-coin",
          url: "",
          submenu: [
            { titulo: "Consolidados", url: "/payment-center/payment-summary" },
            {
              titulo: "Pendientes por pagar",
              //url: "/payment-center/pending-payments",
              url: "/payment-center/payment-summary",
            },
            {
              titulo: "Pendientes por recibir",
              //url: "/payment-center/payments-to-receive",
              url: "/payment-center/payment-summary?status=received",
            },
          ],
        },
        {
          titulo: "Billetera",
          icon: "mdi mdi-credit-card",
          url: "/wallet/method-payment",
          /* submenu: [
            { titulo: 'Métodos de pago', url: '/wallet/method-payment' },
            { titulo: 'Facturación', url: '/wallet/billing' }
          ] */
        },
        {
          titulo: "Usuarios",
          icon: "mdi mdi-account-outline",
          url: "/users",
          submenu: [],
        },
        /*{
          titulo: 'Referidos',
          icon: 'mdi mdi-account-star',
          url: '/referrals/referrals-types'
        },*/
        {
          titulo: "Kamgus Store",
          icon: "mdi mdi-store",
          url: "/store/products",
          /*submenu: [
            { titulo: 'Articulos', url: '/store/products' },
            { titulo: 'Tienda', url: '/store/stores' },
            { titulo: 'Pedidos', url: '/store/deliveries' }
          ]*/
        },
        {
          titulo: "Carga consolidada",
          icon: "mdi mdi-truck",
          url: "/massive-load/products",
          /*submenu: [
            { titulo: 'Productos', url: '/massive-load/products' },
            { titulo: 'Tiendas', url: '/massive-load/stores' },
            { titulo: 'Seguimientos', url: '/massive-load/follows' }
          ]*/
        },
        {
          titulo: "Soporte",
          icon: "mdi mdi-headphones",
          url: "/support/",
          /*submenu: [
            { titulo: 'Soporte', url: '/support/' },
            { titulo: 'Preguntas', url: '/support/faq' }
          ]*/
        },
        /* {
          titulo: 'Configuraciones',
          icon: 'mdi mdi-settings',
          url: '/',
          submenu: []
        },*/
        /* {
          titulo: 'Errores',
          icon: 'mdi mdi-bug',
          url: '/',
          submenu: []
        },  */
      ];
    }



    if ( localStorage.getItem('token') ) {
      this.sharedService.showUser().subscribe(data => {
        this.user = data.data.user;
      });
    }
  }

  logOut() {
    // this._sb.menu = [];
    localStorage.clear();
    this.router.navigate(['/login']);
  }

}
