import { environment } from './../../../environments/environment';
import { ApiService } from './../api/api.service';
var PayoutsService = /** @class */ (function () {
    function PayoutsService(api) {
        this.api = api;
        this.activeFilter = {};
        this.activeLink = "";
    }
    PayoutsService.prototype.listStates = function () {
        return this.api.genericGet(environment.payouts.states);
    };
    PayoutsService.prototype.list = function (limit, offset) {
        var url = this.applyUrlFilters(environment.payouts.base);
        url += "limit=" + limit + "&";
        url += "offset=" + offset + "&";
        return this.api.genericGet(url);
    };
    PayoutsService.prototype.listCustomers = function (driverId, limit, offset) {
        var url = this.applyUrlFilters(environment.payouts.customers + "/" + driverId);
        url += "limit=" + limit + "&";
        url += "offset=" + offset + "&";
        return this.api.genericGet(url);
    };
    PayoutsService.prototype.listCompanies = function (limit, offset, status) {
        if (status === void 0) { status = "preordered"; }
        var url = this.applyUrlFilters(environment.payouts.pending);
        url += "limit=" + limit + "&";
        url += "offset=" + offset + "&";
        return this.api.genericGet(url);
    };
    PayoutsService.prototype.listPendingDriver = function (driverId, limit, offset, status) {
        if (status === void 0) { status = "preordered"; }
        var url = this.applyUrlFilters(environment.payouts.drivers + "/" + driverId);
        url += "limit=" + limit + "&";
        url += "offset=" + offset + "&";
        return this.api.genericGet(url);
    };
    PayoutsService.prototype.updatePayout = function (id, params) {
        if (params === void 0) { params = {}; }
        return this.api.genericPut(environment.payouts.base + "/" + id, params);
    };
    PayoutsService.prototype.driverPayouts = function () {
    };
    PayoutsService.prototype.companyPayouts = function () {
    };
    PayoutsService.prototype.setFilter = function (name, value) {
        this.activeFilter[name] = value;
    };
    PayoutsService.prototype.removeFilters = function () {
        this.activeFilter = {};
    };
    PayoutsService.prototype.applyUrlFilters = function (url) {
        var auxUrl = url + "?";
        for (var _i = 0, _a = Object.keys(this.activeFilter); _i < _a.length; _i++) {
            var filter = _a[_i];
            auxUrl += filter + "=" + this.activeFilter[filter] + "&";
        }
        return auxUrl;
    };
    return PayoutsService;
}());
export { PayoutsService };
